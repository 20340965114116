










import { Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { cloneDeep } from "lodash";
import { NavigationGuardNext, Route } from "vue-router";

import SubscriptionEventForm from "@/settings/components/subscription-events/SubscriptionEventForm.vue";
import UnsavedChangesMixin from "@/shared/mixins/UnsavedChangesMixin";
import { AppSection } from "@/shared/models";
import { SubscriptionEventModel } from "@/settings/models/subscription-events/SubscriptionEventModel";

@Component({
  components: {
    SubscriptionEventForm,
  },
})
export default class RevenueEventView extends mixins(UnsavedChangesMixin) {
  localSubscriptionEvent: SubscriptionEventModel | null = null;
  isWatchedSubscriptionEvent = false;

  get instanceForWatchingUnsavedChanges() {
    // in order to receive the old and new value in the watch during deep viewing
    return cloneDeep(this.localSubscriptionEvent);
  }

  get subscriptionEventId(): string {
    return this.$route.params.subscriptionEventId;
  }

  get subscriptionEvent(): SubscriptionEventModel {
    return this.$store.state.subscriptionEventsStore.subscriptionEvent;
  }

  get isSubscriptionEventFetching(): boolean {
    return this.$store.state.subscriptionEventsStore
      .isSubscriptionEventFetching;
  }

  @Watch("applicationId")
  watchApplicationId() {
    this.$router.push({
      name: AppSection.SUBSCRIPTION_EVENTS,
    });
  }

  @Watch("subscriptionEvent")
  watchSubscriptionEvent(subscriptionEvent: SubscriptionEventModel) {
    this.localSubscriptionEvent =
      SubscriptionEventModel.ofSource(subscriptionEvent);

    if (!this.isWatchedSubscriptionEvent) {
      this.isWatchedSubscriptionEvent = true;
      this.handleWatchingUnsavedChanges();
    }
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (
      this.hasUnsavedChanges &&
      this.currentRoutePath === from.path &&
      !this.isSavedForm
    ) {
      this.showUnsavedChangesDialog(to);
    } else {
      next();
    }
  }

  async created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("appAdmin.subscriptionEvents.editSubscriptionEventTitle")
    );

    await this.$store.dispatch("fetchSubscriptionEvent", {
      applicationId: this.applicationId,
      id: this.subscriptionEventId,
    });
  }

  beforeDestroy() {
    this.$store.commit("clearSubscriptionEvent");
  }

  async save() {
    await this.$store.dispatch("updateSubscriptionEvent", {
      applicationId: this.applicationId,
      event: this.localSubscriptionEvent,
    });
    this.isSavedForm = true;
    this.$router.push({
      name: AppSection.SUBSCRIPTION_EVENTS,
    });
  }
}
